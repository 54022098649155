var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("header"),_c('div',{staticClass:"pb-12"},[_c('div',{staticClass:"container mx-auto pb-12 pt-12 max-w-md"},[_c('automated-advise-view',{attrs:{"data":_vm.advisory,"options":_vm.data.viewOptions}}),(
          _vm.data.viewOptions.checkoutEnabled &&
            this.advisory &&
            _vm.data.viewOptions.manualCheckoutEnabled
        )?_c('div',{staticClass:"max-w-sm mt-6 mx-auto"},[(!this.checkoutComplete)?_c('div',[_c('default-button',{staticClass:"w-full",attrs:{"primary":"","loading":_vm.submitting},nativeOn:{"click":function($event){$event.preventDefault();return _vm.checkout()}}},[_vm._v(" "+_vm._s(_vm.data.viewOptions.checkoutButtonText)+" ")])],1):_vm._e(),_c('transition',{attrs:{"enter-active-class":"transition ease-out duration-100","leave-active-class":"transition ease-in duration-75","enter-class":"transform opacity-0 scale-95","enter-to-class":"transform opacity-100 scale-100","leave-class":"transform opacity-100 scale-100","leave-to-class":"transform opacity-0 scale-95","appear":""}},[(this.checkoutComplete)?_c('div',{staticClass:"max-w-sm mt-6 mx-auto text-l text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.data.viewOptions.checkoutReceipt)+" ")])]):_vm._e()])],1):_vm._e(),(
          _vm.data.viewOptions.checkoutEnabled &&
            _vm.data.viewOptions.automaticCheckoutEnabled &&
            this.advisory &&
            this.checkoutComplete
        )?_c('div',[(this.checkoutComplete)?_c('div',{staticClass:"max-w-sm mt-6 mx-auto text-xl text-center"},[_c('span',[_vm._v(" "+_vm._s(_vm.data.viewOptions.checkoutReceipt)+" ")])]):_vm._e()]):_vm._e()],1)]),_vm._t("default",null,{"isHidden":this.data.viewOptions.checkoutEnabled,"isValid":this.advisory != null})],2)}
var staticRenderFns = []

export { render, staticRenderFns }