<template>
  <div>
    <div v-if="!data">
      <span class="block text-center">
        {{ options.spinnerText }}
      </span>
      <div class="loader mx-auto mt-6" />
    </div>
    <div v-else>
      <span class="block text-center mb-4">
        {{ options.canPerscribeText }}
      </span>
      <div class="max-w-sm mx-auto">
        <div class="rounded shadow-lg mb-4">
          <div class="p-6">
            <div class="font-bold text-xl mb-2">
              <span v-html="primary.value" />
            </div>
            <div v-html="primary.description" />
            <a
              class="inline-block duration-150 text-sm underline font-bold py-3"
              :href="primary.readMoreLink"
              v-if="primary.readMoreLink"
              target="_blank"
            >Les mer
            </a>
          </div>
        </div>
        <div
          class="font-bold text-base mt-8 mb-2 px-6 text-center"
          v-if="secondaries.length"
        >
          {{ options.alternativesText }}
        </div>
        <div
          class="rounded shadow-lg grid grid-cols-1 gap-6 p-6"
          v-for="recommendation in secondaries"
          :key="recommendation.name"
        >
          <div class="font-bold text-lg mb-3">
            <span v-html="recommendation.value" />
          </div>
          <div
            v-html="recommendation.description"
            v-if="recommendation.description"
          />
          <a
            class="inline-block duration-150 text-sm underline font-bold py-3"
            :href="recommendation.readMoreLink"
            target="_blank"
            v-if="recommendation.readMoreLink"
          >Les mer</a
          >
        </div>
      </div>
      <div class="max-w-sm mx-auto">
        <div
          class="rounded shadow-lg grid grid-cols-1 gap-6 p-6"
          v-if="messageToPatient && options.messageToPatientEnabled"
        >
          <span class="text-center font-semibold">Obs: </span>
          <span
            class="block text-left mb-4"
            v-html="messageToPatient" />
        </div>
      </div>
      <div v-if="canPerscribe">
        <div
          class="max-w-sm mt-6 mx-auto"
          v-if="options.doctorCommentEnabled">
          <div class="mb-8">
            <div class="max-w-sm mx-auto mt-4">
              <input-textarea
                v-model="doctorComment"
                label="Valgfri kommentar til lege"
                class="mb-4 w-full"
              />
            </div>
          </div>

          <default-button
            @click.prevent.native="$emit('doctorCommentActionClicked')"
            primary
            class="w-full"
          >
            {{ options.actionButtonText }}
          </default-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default() {
        return null
      }
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },

  data() {
    return {
      added: false,
      doctorComment: null
    }
  },
  watch: {
    doctorComment(value) {
      this.$emit("doctorCommentUpdated", value)
    }
  },
  computed: {
    primary() {
      const forPatientPrimary = this.data?.outputs?.find(e => e.type == "Recommendation" && e.name == "Recommendation 1" && e.calculationFor == "PATIENT")
      if (forPatientPrimary) {
        return forPatientPrimary
      }

      const forAll = this.data?.outputs?.find(e => e.type == "Recommendation" && e.name == "Recommendation 1" && e.calculationFor == "ALL")
      return forAll
    },
    secondaries() {
      const nonPrimariesForPatient = this.data?.outputs?.filter(e => e.type == "Recommendation" && e.name != "Recommendation 1" && e.value != "Ingenting" && (e.calculationFor == "PATIENT" || e.calculationFor == "ALL"))
      return nonPrimariesForPatient
    },
    messageToPatient() {
      let messageToPatientOutput = this.data?.outputs?.find(o => o.type === "MessagePatient")
      if (messageToPatientOutput) {
        if (messageToPatientOutput.value === "Ingen beskjed") {
          return null
        }
        return messageToPatientOutput.value
      }
      return null
    },
    canPerscribe() {
      if (!this.data) {
        return false
      }


      let isValidOutput = this.data?.outputs?.find(o => o.type === "CanPerscribe")
      if(!isValidOutput)
      {
        return false
      }
      if (isValidOutput.value === "IKKE FERDIG UTFYLT") {
        return false
      }

      const first = this.primary?.value
      if (!first || first === "Ingenting") {
        return false
      }
      return true
    }
  }
}
</script>