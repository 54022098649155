<template>
  <div>
    <slot name="header" />
    <div class="pb-12">
      <div class="container mx-auto pb-12 pt-12 max-w-md">
        <automated-advise-view
          :data="advisory"
          :options="data.viewOptions" />
        <div
          class="max-w-sm mt-6 mx-auto"
          v-if="
            data.viewOptions.checkoutEnabled &&
              this.advisory &&
              data.viewOptions.manualCheckoutEnabled
          "
        >
          <div v-if="!this.checkoutComplete">
            <default-button
              @click.prevent.native="checkout()"
              primary
              :loading="submitting"
              class="w-full"
            >
              {{ data.viewOptions.checkoutButtonText }}
            </default-button>
          </div>
          <transition
            enter-active-class="transition ease-out duration-100"
            leave-active-class="transition ease-in duration-75"
            enter-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
            appear
          >
            <div
              class="max-w-sm mt-6 mx-auto text-l text-center"
              v-if="this.checkoutComplete"
            >
              <span> {{ data.viewOptions.checkoutReceipt }} </span>
            </div>
          </transition>
        </div>
        <div
          v-if="
            data.viewOptions.checkoutEnabled &&
              data.viewOptions.automaticCheckoutEnabled &&
              this.advisory &&
              this.checkoutComplete
          "
        >
          <div
            class="max-w-sm mt-6 mx-auto text-xl text-center"
            v-if="this.checkoutComplete"
          >
            <span> {{ data.viewOptions.checkoutReceipt }} </span>
          </div>
        </div>
      </div>
    </div>
    <slot
      :is-hidden="this.data.viewOptions.checkoutEnabled"
      :is-valid="this.advisory != null" />
  </div>
</template>
<script>
import axios from "axios"
import AutomatedAdviseView from "../AutomatedAdviseView.vue"

export default {
  components: {
    AutomatedAdviseView
  },
  props: {
    data: {
      type: Object,
      default() {
        return {}
      }
    },
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    submitting: {
      type: Boolean,
      default() {
        return false
      }
    },
    submit: {
      type: Function,
      default: () => { }
    }
  },

  data() {
    return {
      advisory: null,
      bufferComplete: false,
      checkoutComplete: false
    }
  },

  methods: {
    fetchData() {
      axios.post("/api/reference/calculation", { referenceCode: this.formData.referenceCode })
        .then(({ data }) => {
          if (data.reference_aa !== null) {
            this.advisory = data.reference_aa.automated_advice.advisory
            if(this.data.viewOptions.checkoutEnabled && this.data.viewOptions.automaticCheckoutEnabled)
            {
              this.checkout()
            }
          }
        })
    },

    checkout() {
      this.checkoutComplete = false
      this.submit(false)
      this.checkoutComplete = true
    }
  },

  created() {
    setTimeout(() => {
      this.fetchData()
      this.bufferComplete = true
    }, 5000)
    
  },

  computed: {
    answeredQuestions() {
      return this.slideFormData.filter(x => x.type !== "automaticAdvisorSlide"
        && x.type !== "orderSummarySlide" && x.value !== null && x.type !== "aav2")
    }
  }
}
</script>
